<template>
  <div class="wrap-dashboard">
    <div class="main-content">
      <div class="wrap-content">
        <div class="title-page">Holders</div>
        <!-- <div class="filter">
          <div>
            <div class="wrap-input">
              <label class="label-input">Depositor</label>
              <input type="text" name="example" list="exampleList" class="input-datalist" />
              <datalist id="exampleList">
                <option value="ALL"></option>
                <option value="LP1"></option>
                <option value="LP2"></option>
                <option value="LP3"></option>
                <option value="IB1 ABI KB"></option>
              </datalist>
            </div>
            <div class="wrap-input">
              <label class="label-input">Total Weight</label>
              <span>gr</span>
            </div>
          </div>
          <div>
            <div class="wrap-input">
              <label class="label-input">Periode</label>
              <select>
                <option selected value="all">Year</option>
                <option value="lp1">Monthly</option>
                <option value="lp2">Weekly</option>
                <option value="lp3">Daily</option>
              </select>
            </div>
            <div class="wrap-input">
              <label class="label-input">Metal Type</label>
              <span>Gold</span>
            </div>
          </div>
          <div>
            <div class="wrap-input">
              <label class="label-input">Trading Contract</label>
              <select>
                <option value="ALL" selected>ALL</option>
                <option value="0.01gr">KAU 0.01gr</option>
                <option value="1gr">1gr</option>
              </select>
            </div>
            <div class="wrap-input">
              <label class="label-input">Total Storage Fee </label>
              <span>200000</span>
            </div>
          </div>
          <div>
            <div class="wrap-input">
              <label class="label-input">Allocated</label>
              <select>
                <option selected value="all">ALL</option>
                <option value="lp1">Pool</option>
                <option value="lp2">Non Pool</option>
              </select>
            </div>
            <div class="wrap-input">
              <label class="label-input">Hub</label>
              <span>Jakarta</span>
            </div>
          </div>
        </div> -->
        <div class="content">
          <table>
            <thead>
              <tr id="top">
                <th scope="col" rowspan="2" style="width: 70px">No</th>
                <!-- <th scope="col" rowspan="2" style="width: 120px">Executed Date</th> -->
                <th scope="col" rowspan="2">HIN</th>
                <!-- <th scope="col" rowspan="2">Account</th> -->
                <th scope="col" rowspan="2">IB Code</th>
                <th scope="col" rowspan="2">Quantity</th>
                <th scope="col" rowspan="2">Weight (gr)</th>
                <th scope="col" rowspan="2">ID Contract</th>
                <th scope="col" colspan="4">Storage Information</th>
                <th scope="col" rowspan="2">Bullion Status</th>
                <th scope="col" rowspan="2">Detail</th>
              </tr>
              <tr>
                <!-- <th scope="col">Serial Number</th>
                <th scope="col">Year</th> -->
                <th scope="col">Days</th>
                <th scope="col">$USD</th>
                <th scope="col">IDR</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <!-- <td>12 sept 2021</td> -->
                <td>LP000003</td>
                <!-- <td>123445</td> -->
                <td>QWERTY123</td>
                <td>12</td>
                <td>10</td>
                <td>1gr</td>
                <td>33</td>
                <td>3363,29</td>
                <td>49957299</td>
                <td>Paid</td>
                <td>Collateralized</td>
                <td>
                  <button type="button" class="btn btn-outline-info btn-sm" v-b-modal.modal-1>Detail</button>
                </td>
              </tr>
            </tbody>
          </table>
          <b-modal id="modal-1" title="Holder History" hide-footer size="xl">
            <button type="button" class="btn btn-scondary mb-3 text-right" @click="Download">Download</button>
            <table id="tableDetail">
              <thead>
                <tr id="top">
                  <th scope="col" style="width: 70px">No</th>
                  <th scope="col" style="width: 120px">Trade Matched Id</th>
                  <th scope="col">TimeStamp</th>
                  <!-- <th scope="col">Rate</th> -->
                  <th scope="col">Order Id</th>
                  <th scope="col">Trade Id</th>
                  <th scope="col">Total(gr)</th>
                  <th scope="col">Direction</th>
                  <!-- <th scope="col" colspan="4">Buyer</th>
                <th scope="col" colspan="4">Seller</th> -->
                </tr>
              </thead>
              <!-- <tbody v-if="loadingData">
              <tr>
                <td class="text-center" colspan="12">
                  <b-spinner label="Loading..."></b-spinner>
                </td>
              </tr>
            </tbody> -->
              <tbody>
                <tr>
                  <td data-label="No" style="border-right: 1px solid #ddd">1</td>
                  <td data-label="Trade Match Id" style="border-right: 1px solid #ddd">93209</td>
                  <td data-label="Time Stamp" style="border-right: 1px solid #ddd">28 September 2022 16:20</td>
                  <!-- <td data-label="Rate" style="border-right: 1px solid #ddd">
                    <div>Rp 15,040</div>
                    
                  </td> -->
                  <td data-label="Order Id" class="description">15600</td>
                  <td data-label="Remarks">
                    037-PC008833-93212
                    <!-- <div v-show="data.direction[0].tradebuy">
                    <span v-if="role == 6">
                      <img src="../assets/cimb.png" alt="" class="icon-trade" v-show="data.direction[0].tradebuy[1] == '022'" />
                      <img src="../assets/bagi.png" alt="" class="icon-trade" v-show="data.direction[0].tradebuy[1] == '037'" />
                    </span>
                    <span v-else>
                      {{ data.direction[0].tradebuy[1] }}
                    </span>
                    <span>
                      {{ " " + data.direction[0].tradebuy[0] + "-" }}
                    </span>
                    <span>{{ data.direction[0].tradebuy[2] }}</span>
                  </div> -->
                  </td>
                  <td data-label="VA Code">1</td>
                  <td data-label="Amount" style="border-right: 1px solid #ddd">Buy</td>
                </tr>
                <tr>
                  <td data-label="No" style="border-right: 1px solid #ddd">2</td>
                  <td data-label="Trade Match Id" style="border-right: 1px solid #ddd">93205</td>
                  <td data-label="Time Stamp" style="border-right: 1px solid #ddd">27 September 2022 14:20</td>

                  <td data-label="Order Id" class="description">15589</td>
                  <td data-label="Remarks">037-PC008822-93110</td>
                  <td data-label="VA Code">0.1</td>
                  <td data-label="Amount" style="border-right: 1px solid #ddd">Sell</td>
                </tr>
                <!-- <tr v-show="data.length == 0">
                <td colspan="12">No Data Available</td>
              </tr> -->
              </tbody>
            </table>
          </b-modal>
          <!-- <b-pagination
            v-model="currentPage"
            :total-rows="lengthData"
            :per-page="perPage"
            @change="handlePageChange"
            aria-controls="new-table"
            first-number
            last-number
            v-show="lengthData > 10"
          ></b-pagination> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dataTransaction: [],
      pageOfItems: [],
      currentPage: 1,
      perPage: 10,
      lengthData: 1,
      trade_id: [],
      offset: 0,
      data: [],
      form: {
        startDate: "",
        endDate: "",
      },
    };
  },
  mounted() {
    this.getData(this.currentPage);
  },
  methods: {
    handlePageChange(value) {
      this.getData(value);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    Download() {
      // var mywindow = window.open("", "PRINT", "height=400,width=600");

      // mywindow.document.write("<html><head><title>" + document.title + "</title>");
      // mywindow.document.write("</head><body >");
      // mywindow.document.write("<h1>" + document.title + "</h1>");
      // mywindow.document.write(document.getElementById("tableDetail").innerHTML);
      // mywindow.document.write("</body></html>");

      // mywindow.document.close(); // necessary for IE >= 10
      // mywindow.focus(); // necessary for IE >= 10*/

      // mywindow.print();
      // mywindow.close();

      var divToPrint = document.getElementById("tableDetail");
      var htmlToPrint = "" + '<style type="text/css">' + "table th, table td {" + "border:1px solid #000;" + "padding:0.5em;" + "}" + "</style>";
      htmlToPrint += divToPrint.outerHTML;
      var newWin = window.open("");
      newWin.document.write(htmlToPrint);
      newWin.print();
      newWin.close();
    },
  },
  computed: {
    rows() {
      return (this.currentPage - 1) * this.perPage;
    },
  },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.title-page {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 40px;
}

/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.content {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.icon-trade {
  width: 25px;
}

.footer {
  background: var(--primary-color);
  color: white;
  padding: 20px 47px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  height: 62px;
}

.filter {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 12px;
  flex-wrap: wrap;
}

.wrap-input {
  margin-bottom: 10px;
}
.label-input {
  width: 108px;
}
select {
  width: 130px;
  border-radius: 3px;
  border-color: #a5a5a5a5;
  padding: 3px;
}

.input-datalist {
  border-radius: 3px;
  border: 1px solid #a5a5a5a5;
  padding: 1.5px 5px;
}

.card {
  width: 314px;
  font-size: 14px;
  border-radius: 10px;
}

.card-header {
  padding: 1rem;
}

.buyer,
.seller,
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 12px;
  position: relative;
  left: 20px;
}

.title::before {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--scondary-color);
  left: -17px;
  top: 3px;
}

.buyer {
  margin-bottom: 20px;
}

.content-right {
  text-align: right;
}

.amount {
  font-weight: bold;
}

.btn-primer {
  background: var(--scondary-color);
  border: none;
  color: #ffffff;
  padding: 5px 25px;
  border-radius: 5px;
}

.btn-primer:hover {
  background-color: var(--scondary-color-hover);
}

@media print {
  * {
    display: none;
  }
  table {
    display: block;
  }
}
</style>
